.modal-overlay {
  @apply fixed inset-0 backdrop-filter backdrop-blur-sm rounded-sm z-200 bg-black bg-opacity-10;
}

.modal-content {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0 outline-none focus:outline-none z-200;
}

.modal {
  @apply z-200 flex flex-col items-center justify-center p-1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.body {
  @apply bg-white pt-0 pb-4 sm:pt-0 sm:pb-4 relative rounded-sm;
}

.footer {
  @apply shadow-xl bg-gray-50 px-4 py-3 sm:px-6 sm:flex;
}

.close {
  padding: 2px;
  font-size: 14px;
}
