.responsive-swiper {
  :global {
    .swiper-wrapper {
      @apply py-2;
    }
  }
}

.responsive-swiper-nav {
  :global {
    .swiper-pagination-bullet {
      @apply h-3 w-3 bg-[#5D5D5D] cursor-pointer;
      margin-left: 6px !important;
      margin-right: 6px !important;
    }

    .swiper-pagination-bullet-active {
      @apply bg-gold;
    }
  }
}
